import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import './LinkPage.css'

export const LinkPageTemplate = ({ title, subtitle, featuredImage, link_list}) => (
  <main className="Link">
    <PageHeader
      large
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
    />

	{link_list.map((list, index) => (
    <section className="section Link--Section1" key={index}>
      <div className="container Link--Section1--Container">
        <div className="Link--Section1--Title">
			<h2>{list.category}</h2>
        </div>
		<div className="Link--Details">
			<Content source={list.links} />
		</div>
      </div>
    </section>
	))}
  </main>
)

// Export Default LinkPage for front-end
const LinkPage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <LinkPageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default LinkPage

export const pageQuery = graphql`
  query LinkPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
		subtitle
        featuredImage
		link_list{
			category
			links
		}
      }
    }
  }
`
